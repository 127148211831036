import gsap from "gsap";

export default class Single
{
	constructor (el)
	{	
		this.elmt = el.querySelector('.marquee div');
		this.deltaY = this.posX = this.inten = 0;
		this.speed = 0.1;
	}

	init ()
	{
		this.xSet = gsap.quickSetter(this.elmt, "x", "px");

		this.height = this.elmt.clientWidth
		console.log('ici', this.height)

		this.addTicker = () => {
	      this.playTicker();
	    }
	    gsap.ticker.add(this.addTicker);
		
		window.addEventListener('wheel', this.ref = (e) => {
			this.deltaY = e.deltaY;
			window.clearTimeout( this.isWheeling );
            this.isWheeling = setTimeout( (e) => {
                this.deltaY = 0;
            }, 66);
        });
	}

	playTicker(){
		const gDt = gsap.ticker.deltaRatio()
		const dt = 1.0 - Math.pow(1.0 - this.speed, gDt);

		this.xSet(this.posX);

		this.inten += (this.deltaY/4 - this.inten) * dt;
		this.posX += gDt + this.inten;

		if(this.posX > 0) {
			this.posX = - this.height/2;
        }
        if(this.posX < -(this.height) / 2) {
        	this.posX = this.posX + this.height/2;
        }
	}

	destroy()
	{
		window.removeEventListener('wheel', this.ref);
		gsap.ticker.remove(this.addTicker);
	}
}