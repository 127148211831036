// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import GScroll from "modules/GScroll";
import Single from "modules/Single";

gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);

import {indexInParent, shuffle, isTouch} from "modules/utils"

history.scrollRestoration = "manual";
window.onbeforeunload = function () {
	window.scrollTo(0, 0);
}

document.addEventListener("DOMContentLoaded", function() {

	// window.pageYOffset = 0;
	// window.scrollTo(0, 0);
	let scroll;
	if(!isTouch()){
		scroll = new GScroll('body', 0.24)
		scroll.init();
	}
    // scroll.current = scroll.scrollTop = 0;

	let offset = 0, classLogo = "";

	document.addEventListener('click', function (event) {
		if (event.target.closest('.onglet')){

			gsap.killTweensOf('.reponse');

			let indexBtn = indexInParent(event.target.closest('.onglet').parentNode.parentNode); // car scrollrrigg ajoute du dom
			if(window.innerWidth < 768){
				indexBtn = indexInParent(event.target.closest('.onglet').parentNode);
			}

			document.querySelector('.onglet.current').classList.remove('current');
        	document.querySelectorAll('.innerOnglet')[indexBtn].querySelector('button').classList.add('current');

        	gsap.set(document.querySelector('.reponse.current'), {autoAlpha:0})
        	document.querySelector('.reponse.current').classList.remove('current')
        	gsap.fromTo(document.querySelectorAll('.reponse')[indexBtn], {
        		autoAlpha:0,
        		y:'-20px'
        	}, {
        		y:'0px',
        		autoAlpha:1,
        		ease:'power2.out',
        		duration:1
        	})
        	document.querySelectorAll('.reponse')[indexBtn].classList.add('current')

        // pop in transition
        }else if (event.target.closest('.toPopIn')){
        	if(scroll != undefined){
        		scroll.unwheel();
        	}

        	offset = window.pageYOffset;

        	const el = event.target.closest('.toPopIn');
        	gsap.to(el, {
        		autoAlpha: 0,
        		y:'-20%',
        		ease:"power4.in",
        		duration:0.5,
        	})
        	gsap.to('header', {
        		autoAlpha: 0,
        		y:'-20%',
        		ease:"power4.in",
        		duration:0.5,
        	})

        	gsap.delayedCall(0.5, () => {
        		document.querySelector('main').classList.add('off');
        		window.scrollTo(0, 0)

        		if(scroll != undefined){
        			scroll.current = scroll.scrollTop = 0;
        		}

        		if(el.classList.contains('toPopIn1')){
        			document.querySelector('.single-1').classList.remove('off');
        			single1.init();

        			gsap.fromTo('.single-1', {
	        			y:'100vh'
	        		}, {
	        			y:'0vh',
	        			duration:0.8,
	        			ease:'power4.out'
	        		})
        		}else{
        			document.querySelector('.single-2').classList.remove('off');
        			single2.init();

        			gsap.fromTo('.single-2', {
	        			y:'100vh'
	        		}, {
	        			y:'0vh',
	        			duration:0.8,
	        			ease:'power4.out'
	        		})
        		}

        		if(scroll != undefined){
	        		scroll.resize();
	        		scroll.wheel();
	        	}
        		
        	})

        // close single
        }else if (event.target.closest('.close')){

        	single1.destroy();
        	single2.destroy();

        	if(scroll != undefined){
        		scroll.unwheel();
        	}

        	document.querySelector('.single-1').classList.add('off');
        	document.querySelector('.single-2').classList.add('off');

        	document.querySelector('main').classList.remove('off');

        	if(scroll != undefined){
        		scroll.resize();
        	}
        	
        	window.scrollTo(0, offset)
        	
        	if(scroll != undefined){
	        	scroll.scrollTop = offset;
	        	scroll.current = -offset; // IMPORTANT

	        	scroll.wheel();
	        }

        	gsap.to('.toPopIn, header', {
        		autoAlpha: 1,
        		y:'0%',
        		ease:"power4.out",
        		duration:1
        	})

        // close popin
        }else if (event.target.closest('.closePop') || event.target.closest('#fondPopIn')){

        	gsap.to('#fondPopIn', {
				autoAlpha:0,
				duration:1,
				ease:'none'
			})

			gsap.to('#popIn', {
				y:'150%',
				rotate:0,
				ease:'power4.inOut',
				duration:1
			})

        // to Menu
        }else if (event.target.closest('.toMenu')){

        	classLogo = document.querySelector('.logo svg').getAttribute('class');
        	
        	
        	document.querySelector('.toMenu').classList.add('off')
        	if(scroll != undefined){
        		scroll.unwheel();
        	}
        	document.getElementById('menu').classList.remove('off')

        	offset = window.pageYOffset;

        	gsap.to('#menu, .innerMenu2', {
        		y:'0%',
        		ease:'power4.inOut',
        		duration:1.4,
        		onComplete:() => {

        			document.body.classList.add('menuOuvert')
        			document.querySelector('.logo svg').setAttribute('class', '');

        			if(scroll == undefined){
						document.querySelector('main').classList.add('off2');
        				window.scrollTo(0, 0)
        			}
        		}
        	})

        	let i = 0.4;
        	document.querySelectorAll('#menu ul li').forEach(li => {
        		gsap.fromTo(li, {
        			y:'120px'
        		},{
        			y:'0px',
        			ease:'power4.out',
        			duration:i,
        		})

        		i += 0.55;
        	})
        	gsap.fromTo('#menu ul', {
        		y:'80px'
        	},{
        		y:'0px',
        		ease:'power2.out',
        		duration:2
        	})

        }else if (event.target.closest('.toFermer')){

        	document.body.classList.remove('menuOuvert')
        	document.querySelector('.logo svg').setAttribute('class', classLogo);

        	if(scroll != undefined){
        		scroll.wheel();
        	}

        	gsap.to('#menu, .innerMenu2', {
        		y:'100%',
        		ease:'power4.inOut',
        		duration:1.4
        	})
        	gsap.to('.innerMenu2', {
        		y:'-100%',
        		ease:'power4.inOut',
        		duration:1.4,
        		onComplete: () => {
        			document.querySelector('.toMenu').classList.remove('off')
        			document.getElementById('menu').classList.add('off')
        		}
        	})

        	if(scroll == undefined){
        		document.querySelector('main').classList.remove('off2');
				window.scrollTo(0, offset)
			}

        // back to top
        }else if (event.target.closest('.toTop')){

        	document.getElementById('fauxDebut').classList.remove('devant')
        	if(scroll != undefined){
        		scroll.unwheel();
        	}

        	gsap.set('#fauxDebut', {
        		autoAlpha:1
        	})
        	gsap.to('#fauxDebut h2', {
		 		y:'-20px',
		 		ease:'power4.out',
		 		duration:3,
		 		delay:0.5
		 	})
        	gsap.to('#fauxDebut .l', {
        		y:0,
		 		ease:'power4.out',
		 		duration:1.6,
		 		stagger:-0.1,
		 		delay:0.5,
        	})

        	gsap.fromTo('.heroFleche img', {
        		y:'-200%'
        	},{
		 		y:'-50%',
		 		ease:'power3.out',
		 		duration:2.6,
		 		delay:0.5
		 	})

        	// gsap.to('#fauxDebut', {
        	// 	backgroundColor:'#002921',
        	// 	color:'#FFFFFF',
        	// 	delay:2.3,
        	// 	duration:0.2
        	// })


        	gsap.delayedCall(2.5, ()=> {

        		gsap.fromTo('.logoFull', {
        			y:'-300%',
        			opacity:0
        		},{
        			y:'0%',
        			opacity:1,
        			duration:0.5,
        			ease:'power3.out'
        		})

        		gsap.set('#fauxDebut', {autoAlpha:0})
        		if(scroll != undefined){
    				scroll.wheel();
    			}

    			gsap.set('footer', {clearProps:'all'})
    			// gsap.set('.innerFoot', {clearProps:'all'})
    			gsap.set('#fauxDebut h2', {clearProps:'all'})
    			gsap.set('#fauxDebut .l', {clearProps:'all'})

        	})

        	gsap.to('footer', {
        		y: -1 * (window.innerHeight + 80) + 'px', // recalculer distance
        		ease:'power4.inOut',
        		duration:1.4,
        		onComplete: () => {
        			document.getElementById('fauxDebut').classList.add('devant');
        			window.scrollTo(0, 0);
        			if(scroll != undefined){
        				scroll.current = scroll.scrollTop = 0;
        			}
        		}
        	})
        	// gsap.to('.innerFoot', {
        	// 	autoAlpha:0,
        	// 	ease:'power4.inOut',
        	// 	duration:1.4
        	// })
        	
        }else if(event.target.closest('.toAncre')){

        	document.body.classList.remove('menuOuvert')

        	document.querySelector('.logo svg').setAttribute('class', classLogo);
        	document.querySelector('main').classList.remove('off2');

        	let ajout = 0;

        	if(
        		event.target.closest('.toAncre').getAttribute('data-id') == 's-pushs' ||
        		event.target.closest('.toAncre').getAttribute('data-id') == 's-ici' ||
        		event.target.closest('.toAncre').getAttribute('data-id') == 's-interlude'
        	){
        		ajout = 1;
        	}else if(
        		event.target.closest('.toAncre').getAttribute('data-id') == 's-debuter'
        	){
        		ajout = -200;
        	}

        	const dist = document.querySelector('.'+event.target.closest('.toAncre').getAttribute('data-id')).getBoundingClientRect().top + window.pageYOffset + ajout;
        	
        	if(scroll != undefined){
        		scroll.wheel();
        	}

        	window.scrollTo(0, dist)

        	if(scroll != undefined){
	        	scroll.scrollTop = dist;
	        	scroll.current = - dist;
	        }

        	// on reprend la fermeture


        	gsap.to('#menu, .innerMenu2', {
        		y:'100%',
        		ease:'power4.inOut',
        		duration:1.4
        	})
        	gsap.to('.innerMenu2', {
        		y:'-100%',
        		ease:'power4.inOut',
        		duration:1.4,
        		onComplete: () => {
        			document.querySelector('.toMenu').classList.remove('off')
        			document.getElementById('menu').classList.add('off')
        		}
        	})

       	// SHOWREEL
        }else if (event.target.closest('.toShowreel')){

        	if(scroll != undefined){
        		scroll.unwheel();
        	}

        	gsap.to('main, header', {
        		autoAlpha:0,
        		duration:0.3
        	})
        	gsap.to('#showreel', {
        		autoAlpha:1,
        		duration:0.3,
        		delay:0.3
        	})
        	document.querySelector('#showreel video').currentTime = 0;
        	document.querySelector('#showreel video').play()
        }else if (event.target.closest('#showreel')){

        	if(scroll != undefined){
        		scroll.wheel();
        	}

        	gsap.to('#showreel', {
        		autoAlpha:0,
        		duration:0.3
        	})
        	gsap.to('main, header', {
        		autoAlpha:1,
        		duration:0.3,
        		delay:0.3
        	})
        	document.querySelector('#showreel video').pause()
        }
        // FIN SHOWREEL
        

    });


	gsap.delayedCall(2, () => {	
		ScrollTrigger.refresh()

		if(scroll != undefined){
			scroll.wheel();
			scroll.resize();
		}

		gsap.set('h1', {
			autoAlpha:1
		})
	 	gsap.to('h1', { //, .h1Bis
	 		y:'-20px',
	 		ease:'power4.out',
	 		duration:3,
	 		delay:0.5
	 	})
	 	gsap.to('h1 .innerL .l', {
	 		y:0,
	 		ease:'power4.out',
	 		duration:1.6,
	 		stagger:0.1,
	 		delay:0.5,
	 	})

	 	gsap.to('.heroFleche img', {
	 		y:'-50%',
	 		ease:'power3.out',
	 		duration:2.6,
	 		delay:0.5
	 	})

	 	gsap.to('.logoFull, header', {
	 		autoAlpha:1,
	 		ease:'power2.inOut',
	 		duration:0.4,
	 		delay:1
	 	})
    })


	if(window.innerWidth > 767){
		
		// ScrollTrigger.create({
		// 	trigger:'.s-hero2',
		// 	start:'top top+=1',
		// 	onEnter:() => {
		// 		document.querySelector('.toMenu').classList.add('invert')
		// 		document.querySelector('header .logo svg').classList.add('invert')
		// 	}
		// })
		gsap.to('.innerImg1', {
			rotation:'30deg',
			y:'-10%',
			x:'30%',
			ease:'none',
			scrollTrigger: {
				trigger:'.trioImg',
				start:'top bottom',
				end:'+=' + 2*window.innerHeight + 'px',
				scrub:true
			}
		})
		gsap.to('.innerImg1 img', { // .innerImg1 img
			rotation:'-30deg',
			ease:'none',
			scrollTrigger: {
				trigger:'.trioImg',
				start:'top bottom',
				end:'+=' + 2*window.innerHeight + 'px',
				scrub:true
			}
		})
		gsap.to('.innerImg2', {
			rotation:'-30deg',
			y:'50%',
			x:'20%',
			ease:'none',
			scrollTrigger: {
				trigger:'.trioImg',
				start:'top bottom',
				end:'+=' + 2.6*window.innerHeight + 'px',
				scrub:true
			}
		})
		gsap.to('.innerImg2 video', {
			rotation:'30deg',
			ease:'none',
			scrollTrigger: {
				trigger:'.trioImg',
				start:'top bottom',
				end:'+=' + 2.6*window.innerHeight + 'px',
				scrub:true
			}
		})
		gsap.to('.innerImg3', {
			rotation:'20deg',
			y:'30%',
			x:'-90%',
			ease:'none',
			scrollTrigger: {
				trigger:'.trioImg',
				start:'top 50%',
				end:'+=' + 2.4*window.innerHeight + 'px',
				scrub:true
			}
		})
		gsap.to('.innerImg3 img', {
			rotation:'-20deg',
			ease:'none',
			scrollTrigger: {
				trigger:'.trioImg',
				start:'top 50%',
				end:'+=' + 2.4*window.innerHeight + 'px',
				scrub:true
			}
		})

		gsap.to('.motGauche', {
			x:"0.7em",
			ease:'power3.inOut',
			scrollTrigger:{
				trigger: '.mask',
				start: 'top 100%',
				endTrigger: '.innerImg3',
				end: 'bottom top',
				scrub: true
			}
		})
		gsap.to('.motDroite', {
			x:"-0.7em",
			ease:'power3.inOut',
			scrollTrigger:{
				trigger: '.mask',
				start: 'top 100%',
				endTrigger: '.innerImg3',
				end: 'bottom top',
				scrub: true
			}
		})


		// supprime temporairement
		// gsap.to('h1 .l1, .h1Bis .l1', {
		// 	x:'-80%',
		// 	ease:'none',
		// 	scrollTrigger: {
		// 		trigger:'.trioImg',
		// 		start:'top bottom',
		// 		end:'bottom bottom+=' + 1*window.innerWidth + 'px',
		// 		scrub:true
		// 	}
		// })
		// gsap.to('h1 .l2, .h1Bis .l2', {
		// 	x:'30%',
		// 	ease:'none',
		// 	scrollTrigger: {
		// 		trigger:'.trioImg',
		// 		start:'top bottom',
		// 		end:'bottom bottom+=' + 1*window.innerWidth + 'px',
		// 		scrub:true
		// 	}
		// })
		// gsap.to('h1 .l3, .h1Bis .l3', {
		// 	x:'-250%',
		// 	ease:'none',
		// 	scrollTrigger: {
		// 		trigger:'.trioImg',
		// 		start:'top bottom',
		// 		end:'bottom bottom+=' + 1*window.innerWidth + 'px',
		// 		scrub:true
		// 	}
		// })
		// gsap.to('h1 .l4, .h1Bis .l4', {
		// 	x:'10%',
		// 	ease:'none',
		// 	scrollTrigger: {
		// 		trigger:'.trioImg',
		// 		start:'top bottom',
		// 		end:'bottom bottom+=' + 1*window.innerWidth + 'px',
		// 		scrub:true
		// 	}
		// })

		// gsap.to('h1', {
		// 	x:'0%',
		// 	ease:'power2.inOut',
		// 	scrollTrigger: {
		// 		trigger:'.trioImg',
		// 		start:'bottom bottom+=' + 1*window.innerWidth + 'px',
		// 		end:'+=' + window.innerWidth + 'px',
		// 		scrub: true,
		// 		onEnter:() => {
		// 			document.querySelector('.innerH1Bis').classList.add('off');
		// 			document.querySelector('h1').classList.add('visible');
		// 		},
		// 		onLeaveBack:() => {
		// 			document.querySelector('.innerH1Bis').classList.remove('off');
		// 			document.querySelector('h1').classList.remove('visible');
		// 		}

		// 	}
		// })

		ScrollTrigger.create({
			trigger:'.mask',
			start:'top top',
			onEnter:() => {
				document.querySelector('.mask').classList.add('visible')
				document.querySelector('header .logo svg').classList.remove('off')
				document.querySelector('.toMenu').classList.add('invert')
			},
			onLeaveBack:() => {
				document.querySelector('.mask').classList.remove('visible')
				document.querySelector('header .logo svg').classList.add('off')
				document.querySelector('.toMenu').classList.remove('invert')
			}
		})

		gsap.to('.logoFull',{
			//autoAlpha:0,
			y:'-400%',
			ease:'power3.in',
			scrollTrigger:{
				trigger:'.mask',
				start:'top top+=' + 0.4 * window.innerWidth,
				end:'top top',
				scrub:true
			}
		})

		ScrollTrigger.create({
			trigger:'.trioImg',
			start:'bottom bottom+=' + 1*window.innerWidth + 'px',
			end:'+=' + window.innerWidth + 'px',
			// onEnter:() => {
			// 	document.querySelector('.mask').classList.add('couleur')
			// },
			// onLeaveBack:() => {
			// 	document.querySelector('.mask').classList.remove('couleur')
			// },
			onLeave:() => {
				document.querySelector('.mask').classList.add('off')
			},
			onEnterBack:() => {
				document.querySelector('.mask').classList.remove('off')
			}
		})

		gsap.to('.part2.part2A', {
			x:'0%',
			ease:'power2.inOut',
			scrollTrigger: {
				trigger:'.part2.part2A',
				start:'top top',
				end:'+=' + window.innerWidth,
				scrub: true,
				pin: true,
				onLeave:() => {
					document.querySelector('header .logo svg').classList.remove('invert')
				},
				onEnterBack:() => {
					document.querySelector('header .logo svg').classList.add('invert')
				}
			}
		})

		ScrollTrigger.create({
			trigger:'.part2.part2A',
			start:'top top',
			onEnter:() => {
				document.querySelector('.toMenu').classList.remove('invert')
			},
			onLeaveBack:() => {
				document.querySelector('.toMenu').classList.add('invert')
			}
		})


		// animation sur serif dans "experiences"
		// const distL = 0.7*window.innerWidth/11 // nombre de lettres dans le mot
		// let incrL = 0;
		// document.querySelectorAll('.part2 .l span').forEach(el => {

		// 	gsap.to(el, {
		// 		x:'0%',
		// 		ease:'none',
		// 		scrollTrigger: {
		// 			trigger:'.trioImg',
		// 			start:'bottom bottom-=' + ((incrL * distL) - 0.7*window.innerWidth) + 'px',
		// 			end:'+=' + (0.7*window.innerWidth - (incrL * distL)) + 'px',
		// 			scrub:true,
		// 			onEnter:() => {
		// 				console.log('iiii')
		// 			}
		// 		}
		// 	})
		// 	incrL++;
		// })


		const distL = (document.querySelector('.part2A').offsetHeight - window.innerHeight)/document.querySelectorAll('.part2A .l').length
		let incrL = 0
		document.querySelectorAll('.part2A .l').forEach(el => {
			gsap.to(el, {
				opacity:1,
				ease:'none',
				immediateRender: false,
				scrollTrigger: {
					trigger:'.trioImg',
					start:'bottom bottom-=' + distL * incrL,
					end:'+=' + 40,
					scrub:true
				}
			})
			incrL++;
		})
		

		ScrollTrigger.create({
	      trigger: '.pin-garder',
	      start: 'top top',
	      end: '+=' + (window.innerWidth * 2 + window.innerHeight) + 'px',
	      pin: true,
	      onEnter: () => {
	        document.querySelector('.pin-garder').classList.add('invert');
	      },
	      onLeaveBack: () => {
	        document.querySelector('.pin-garder').classList.remove('invert');
	      }
	    });

	    

	    gsap.from('.ell', {
	      y: '-70%',
	      rotate: '40deg',
	      ease: 'none',
	      scrollTrigger: {
	        trigger: '.s-garder',
	        start: 'top bottom',
	        end: '+=' + window.innerHeight +'px',
	        scrub: true
	      }
	    });

	    gsap.to('.ell', {
	      scale: 3,
	      rotate: -180,
	      ease: 'none',
	      immediateRender: false,
	      scrollTrigger: {
	        trigger: '.pin-garder',
	        start: 'top top',
	        end: '+=' + 2 * window.innerWidth + 'px',
	        scrub: true
	      }
	    });
	    gsap.fromTo('.s-garder h2', {
	      y: -0.5 * window.innerHeight + 'px'
	    }, {
	      y: '0px',
	      ease: 'none',
	      scrollTrigger: {
	        trigger: '.s-garder',
	        start: 'top bottom',
	        end: '+=' + window.innerHeight + 'px',
	        scrub: true
	      }
	    });


		
		let incr = 25;
		document.querySelectorAll('.s-garder h2 span').forEach(el => {
			gsap.fromTo(el, {
				//y: -1.5 * window.innerHeight + 'px',
				rotate: incr,
				// scale: incr/5,
				x: incr * 0.05*window.innerWidth + 'px'
			},{
				//y:'0px',
				rotate:0,
				scale:1,
				x:'0px',
				ease:'power1.out',
				scrollTrigger:{
					trigger:'.s-garder',
					start:'top bottom',
					end: '+=' + window.innerHeight + 'px',
					scrub: true
				}
			})

			incr += 25;
		})

		gsap.to('.s-garder h2', {
			rotate:0,
			scale:1.5,
			ease:'none',
			immediateRender: false,
			scrollTrigger:{
				trigger:'.s-garder',
				start:'bottom bottom',
				end: '+=' + (2*window.innerWidth) + 'px',
				scrub: true
			}
		})

		let incr2 = 0;
		document.querySelectorAll('.s-garder h2 span').forEach(el => {
			ScrollTrigger.create({
				trigger:'.s-intro',
				start:'top bottom-=' + incr2 + 'px',
				end:'top top',
				scrub: true,
				onEnter:() => {
					el.classList.add('off')
				},
				onLeaveBack:() => {
					el.classList.remove('off')
				}
			})

			incr2 += 25;
		})

		ScrollTrigger.create({
			trigger:'.s-intro',
			start:'top bottom',
			onEnter:() => {
				document.querySelector('.toMenu').classList.add('invert')
				document.querySelector('header .logo svg').classList.add('invert')
			},
			onLeaveBack:() => {
				document.querySelector('.toMenu').classList.remove('invert')
				document.querySelector('header .logo svg').classList.remove('invert')
			}
		})

		ScrollTrigger.create({
			trigger:'.s-intro',
			start:'top top',
			onLeaveBack:() => {
				document.querySelector('.toMenu').classList.add('invert')
				document.querySelector('header .logo svg').classList.add('invert')
			}
		})



		document.querySelectorAll('.innerMot').forEach(el => {
			gsap.to(el.querySelector('.mot'), {
				y:'-5%',
				ease:'none',
				scrollTrigger: {
					trigger:el,
					start:'top 70%',
					end: '+=' + el.clientHeight,
					scrub: true
				}
			})
		})


		ScrollTrigger.create({
			trigger:'.s-pushs',
			start:'top bottom',
			end:'bottom top',
			pin:'.lesCercles'
		})
		gsap.to('.lesCercles .img1', {
			y:'-110%',
			x:'-10%',
			rotation:-30,
			ease:'none',
			scrollTrigger:{
				trigger:'.s-pushs',
				start:'top bottom',
				end:'bottom top',
				scrub: true
			}
		})
		gsap.to('.lesCercles .img2', {
			y:'-120%',
			x:'20%',
			rotation: 50,
			ease:'none',
			scrollTrigger:{
				trigger:'.s-pushs',
				start:'top bottom',
				end:'bottom top',
				scrub: true
			}
		})
		gsap.to('.lesCercles .img3', {
			y:'-180%',
			x:'10%',
			rotation:40,
			ease:'none',
			scrollTrigger:{
				trigger:'.s-pushs',
				start:'top bottom',
				end:'bottom top',
				scrub: true
			}
		})


		let iteration = 0;
		document.querySelectorAll('.s-pushs .nom').forEach(nom => {

			if(!iteration % 2){

				let incr = 20;
				let incr2 = 10;
				nom.querySelectorAll('.lettre').forEach(el => {
					gsap.fromTo(el, {
						y:incr + '%',
						rotate:incr2
					},{
						y:-1 * incr + '%',
						rotate:-1 * incr2,
						ease:'none',
						immediateRender: false,
						scrollTrigger:{
							trigger:nom,
							start:'top bottom',
							end: 'bottom top',
							scrub: true
						}
					})

					incr += 20;
					incr2 += 10;

				})

			}else{
				let incr = 20 * nom.querySelectorAll('.lettre').length;
				let incr2 = 10 * nom.querySelectorAll('.lettre').length;

				nom.querySelectorAll('.lettre').forEach(el => {
					gsap.fromTo(el, {
						y:incr + '%',
						rotate:-1 *incr2
					},{
						y:-1 * incr + '%',
						rotate:incr2,
						ease:'none',
						immediateRender: false,
						scrollTrigger:{
							trigger:nom,
							start:'top bottom',
							end: 'bottom top',
							scrub: true
						}
					})

					incr -= 20;
					incr2 -= 10;

				})
			}
			iteration++;
		})

		// video progress
		// const video = document.getElementById('video');
		// video.onloadedmetadata = (event) => {
		// 	const dura = video.duration;
		// 	ScrollTrigger.create({
		// 		trigger:'.s-sticky',
		// 		start:'top bottom',
		// 		end:'bottom top',
		// 		scrub:true,
		// 		onUpdate: self => {
		// 			//console.log("progress:", Math.round(self.progress * dura * 100) / 100);
		// 			video.currentTime = Math.round(self.progress * dura * 100) / 100;
		// 		}
		// 	})
		// };


		// on fait défiler les images de la séquence
		let currentProgress = 0, step = 0;
		// ScrollTrigger.create({
	 //        trigger: ".s-sticky",
	 //        start: "top bottom", 
	 //        end:"bottom top",

	 //        onUpdate: self => {
	 //            let calc = Math.round(self.progress * 180);
	 //            if(calc != currentProgress){
	 //            	document.querySelector('.s-sticky picture.current').classList.remove('current');
	 //            	document.querySelectorAll('.s-sticky picture')[calc].classList.add('current');
	 //                currentProgress = calc;
	 //            }

	 //            if(self.progress < 0.33 && step != 0){
	 //            	step = 0;
	 //            	document.querySelector('.s-sticky .part.current').classList.remove('current')
	 //            	document.querySelectorAll('.s-sticky .part')[0].classList.add('current')
	 //            }else if(self.progress > 0.33 && self.progress < 0.66 && step != 1){
	 //            	step = 1;
	 //            	document.querySelector('.s-sticky .part.current').classList.remove('current')
	 //            	document.querySelectorAll('.s-sticky .part')[1].classList.add('current')
	 //            }else if(self.progress > 0.66 && step != 2){
	 //            	step = 2;
	 //            	document.querySelector('.s-sticky .part.current').classList.remove('current')
	 //            	document.querySelectorAll('.s-sticky .part')[2].classList.add('current')
	 //            }
	 //        }
	 //    });

	    ScrollTrigger.create({
	    	trigger:'.s-sticky',
	    	start:'top bottom',
	    	end: 'bottom top',
	    	pin: '.s-sticky .droite',
	    	onUpdate: self => {
	            let calc = Math.round(self.progress * 180);
	            if(calc != currentProgress){
	            	document.querySelector('.s-sticky picture.current').classList.remove('current');
	            	document.querySelectorAll('.s-sticky picture')[calc].classList.add('current');
	                currentProgress = calc;
	            }

	            if(self.progress < 0.33 && step != 0){
	            	step = 0;
	            	document.querySelector('.s-sticky .part.current').classList.remove('current')
	            	document.querySelectorAll('.s-sticky .part')[0].classList.add('current')
	            }else if(self.progress > 0.33 && self.progress < 0.66 && step != 1){
	            	step = 1;
	            	document.querySelector('.s-sticky .part.current').classList.remove('current')
	            	document.querySelectorAll('.s-sticky .part')[1].classList.add('current')
	            }else if(self.progress > 0.66 && step != 2){
	            	step = 2;
	            	document.querySelector('.s-sticky .part.current').classList.remove('current')
	            	document.querySelectorAll('.s-sticky .part')[2].classList.add('current')
	            }
	        }
	    })




		const elPire = document.querySelector('.s-pire')
		ScrollTrigger.create({
			trigger:'.s-pire',
			start:'top bottom',
			end:'bottom top',
			onEnter:() => {elPire.classList.add('actif')},
			onLeave:() => {elPire.classList.remove('actif')},
			onEnterBack:() => {elPire.classList.add('actif')},
			onLeaveBack:() => {elPire.classList.remove('actif')},
		})

		gsap.to('.s-pire h2', {
			y:'100%',
			ease:'none',
			scrollTrigger: {
				trigger:'.s-pire h2',
				start:'top 50%',
				end:'+=' + document.querySelector('.s-pire h2').clientHeight + 'px',
				scrub:true
			}
		})



		ScrollTrigger.create({
			trigger: '.s-vignette',
			start: 'top top',
			end: '+=' + 6*window.innerHeight + 'px',
			pin: true
		})

		let incrSpan = - 1.3 * (window.innerHeight / 10);
		document.querySelectorAll('.p2 span').forEach(span => {
			gsap.fromTo(span, {
				rotate: (Math.random() - 0.5) * 30,
			},{
				rotate:0,
				opacity:1,
				y:0,
				scaleY:1,
				ease:'power3.out',
				scrollTrigger: {
					trigger:'.s-textes',
					start: 'bottom top-=' + incrSpan,
					end:'+=' + 0.1 * window.innerHeight,
					scrub:true
				}
			})
			incrSpan += window.innerHeight / 10;
		})
		gsap.to('.s-textes2 .trait', {
			scaleX:1,
			ease:'none',
			scrollTrigger: {
				trigger:'.s-textes',
				start: 'bottom top',
				end:'+=' + 2 * window.innerHeight,
				scrub:true
			}
		})


		let currentProgress2 = 0;
		ScrollTrigger.create({
			trigger: '.innerVignette',
			start: 'top bottom',
			end: 'bottom top',
			onUpdate: self => {
	            let calc = Math.round(self.progress * 140);
	            if(calc != currentProgress2){
	            	document.querySelector('.s-vignette picture.current').classList.remove('current');
	            	document.querySelectorAll('.s-vignette picture')[calc].classList.add('current');
	                currentProgress2 = calc;
	            }
	        }
		})


		gsap.to('.s-vignette .img', {
			width:'100%',
			height:'100.1%',
			borderRadius:'0',
			scrollTrigger: {
				trigger: '.s-vignette',
				start: 'top top',
				end: '+=' + 3*window.innerHeight + 'px',
				scrub: true,
				// onLeaveBack:() => {
				// 	document.querySelector('.toMenu').classList.remove('invert')
				// },
				// onEnter:() => {
				// 	document.querySelector('.toMenu').classList.add('invert')
				// }
				//pin: true,
				// onLeave:() => {
				// 	document.querySelector('.s-vignette .textes').classList.add('actif')
				// }
			}
		})

		ScrollTrigger.create({
			trigger: '.s-textes2',
			start: 'top top',
			end: '+=' + 2*window.innerHeight + 'px',
			pin: true
		})

		

		const distCartes = document.querySelector('.cartes').clientWidth - window.innerWidth;
		gsap.to('.cartes', {
			x: -1 * distCartes + 'px',
			ease:'none',
			// immediateRender:false,
			scrollTrigger:{
				trigger:'.cartes',
				top:'top top',
				end:'+=' + distCartes + 'px',
				scrub:true,
				pin:true,
			}
		})

		ScrollTrigger.create({
			trigger:'.s-debuter',
			onEnter:() => {
				document.querySelector('.s-cartes').classList.add('off')
			},
			onLeaveBack:() => {
				document.querySelector('.s-cartes').classList.remove('off')
			}
		})

		document.querySelectorAll('.carte').forEach(carte => {
			
			const valeurs = {}
			
			// valeurs.x = (Math.random() - 0.5) * 100
			valeurs.x = (Math.floor(Math.random() * (50 - 30 + 1)) + 30) * (Math.round(Math.random()) * 2 - 1)
			// valeurs.y = (Math.random() - 0.5) * 30
			valeurs.y = (Math.floor(Math.random() * (16 - 10 + 1)) + 10) * (Math.round(Math.random()) * 2 - 1)
			// valeurs.rota = (Math.random() - 0.5) * 30
			valeurs.rota = (Math.floor(Math.random() * (20 - 10 + 1)) + 10) * (Math.round(Math.random()) * 2 - 1)
			
			gsap.fromTo(carte, {
				xPercent:valeurs.x,
				yPercent:valeurs.y,
				rotate:valeurs.rota,
			},{
				xPercent:-valeurs.x,
				yPercent:-valeurs.y,
				rotate:-valeurs.rota,
				ease:'none',
				scrollTrigger: {
					trigger:'.s-cartes',
					start:'top top-=' + ((carte.getBoundingClientRect().left - carte.clientWidth) - window.innerWidth),
					end:'+='+ (window.innerWidth + carte.clientWidth*3) + 'px',
					scrub:true
				}
			})
		})

		let zI = 9;
		document.querySelectorAll('.innerOnglet').forEach(btn => {
			
			btn.style.zIndex = zI;
			zI--;

			gsap.to(btn, {
				autoAlpha:1,
				ease:'none',
				scrollTrigger: {
					trigger:btn,
					start: 'bottom bottom-=' + (0.014 * window.innerWidth) + 'px',
					end: '+=' + btn.clientHeight + 'px',
					scrub: true,
					pin:true, // du coup ça fait comme -100% à 0% en translateY
					pinSpacing: false
				}
			})
			gsap.to(btn.querySelector('button'), {
				rotationX:0,
				ease:'none',
				scrollTrigger: {
					trigger:btn,
					start: 'bottom bottom-=' + (0.014 * window.innerWidth) + 'px',
					end: '+=' + (btn.clientHeight * 1.7) + 'px',
					scrub: true
				}
			})
		})



		// POP IN
		let popInLancee = false;
		ScrollTrigger.create({
			trigger:'.s-desole',
			start:'top 50%',
			onEnter:() => {
				if(!popInLancee){
					popInLancee = true;

					gsap.to('#fondPopIn', {
						autoAlpha:1,
						duration:1,
						ease:'none'
					})

					gsap.to('#popIn', {
						y:'0px',
						rotate:0,
						ease:'elastic.out(0.5, 0.24)',
						duration:1,
						delay:0.5
					})

					gsap.to('svg circle', {
						y:'0px',
						ease:'elastic.out(1, 0.3)',
						duration:2,
						delay:0.5
					})
				}
			}
		})

		ScrollTrigger.create({
			trigger:'footer',
			start:'top bottom',
			onEnter:() => {
				console.log('resize')
				scroll.resize()
			}
		})


		document.querySelectorAll('.morph').forEach(el => {
			el.addEventListener('mouseenter', () => {
				gsap.to('#cercle', {
	                morphSVG:'#logo', 
	                duration:0.4,
	                ease:'power4.inOut'
	            })
			})
			el.addEventListener('mouseleave', () => {
				gsap.to('#cercle', {
	                morphSVG:'#cercle', 
	                duration:0.4,
	                ease:'power4.inOut'
	            })
			})
		})

		document.querySelectorAll('.cursor').forEach(el => {
			el.addEventListener('mouseenter', () => {
				document.querySelector('.ball div').classList.add('gros');
			})
			el.addEventListener('mouseleave', () => {
				document.querySelector('.ball div').classList.remove('gros');
			})
		})

	// MOBILE VERSION
	// MOBILE VERSION
	// MOBILE VERSION
	// MOBILE VERSION
	// MOBILE VERSION
	// MOBILE VERSION
	// MOBILE VERSION
	// MOBILE VERSION
	}else {

		document.querySelector('.mask').classList.add('invertMenu')
		document.querySelector('.s-sticky .droite').classList.add('invertMenu')
		ScrollTrigger.create({
			trigger:'.mask',
			start:'top top-=100px',
			onEnter:() => {
				document.querySelector('header a svg').classList.remove('off')
			},
			onLeaveBack:() => {
				document.querySelector('header a svg').classList.add('off')
			} 
		})

		gsap.to('.logoFull',{
			//autoAlpha:0,
			y:'-400%',
			ease:'power3.in',
			scrollTrigger:{
				trigger:'.mask',
				start:'top top+=' + 0.4 * window.innerWidth,
				end:'top top',
				scrub:true
			}
		})

		// let currentProgress = 0;
		// ScrollTrigger.create({
	 //    	trigger:'.s-sticky',
	 //    	start:'top bottom',
	 //    	end: 'bottom top',
	 //    	onUpdate: self => {
	 //            let calc = Math.round(self.progress * 180);
	 //            if(calc != currentProgress){
	 //            	document.querySelector('.s-sticky picture.current').classList.remove('current');
	 //            	document.querySelectorAll('.s-sticky picture')[calc].classList.add('current');
	 //                currentProgress = calc;
	 //            }
	 //        }
	 //   	})

		// let currentProgress2 = 0;
		// ScrollTrigger.create({
		// 	trigger: '.innerVignette',
		// 	start: 'top bottom',
		// 	end: 'bottom top',
		// 	onUpdate: self => {
	 //            let calc = Math.round(self.progress * 140);
	 //            if(calc != currentProgress2){
	 //            	document.querySelector('.s-vignette picture.current').classList.remove('current');
	 //            	document.querySelectorAll('.s-vignette picture')[calc].classList.add('current');
	 //                currentProgress2 = calc;
	 //            }
	 //        }
		// })
	}

	const single1 = new Single(document.querySelector('.single-1'))
	const single2 = new Single(document.querySelector('.single-2'))











	//
	gsap.set(".ball", {xPercent: -50, yPercent: -50});

	const ball = document.querySelector(".ball");
	const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
	const mouse = { x: pos.x, y: pos.y };
	const speed = 0.2;

	const xSet = gsap.quickSetter(ball, "x", "px");
	const ySet = gsap.quickSetter(ball, "y", "px");

	window.addEventListener("mousemove", e => {    
	  mouse.x = e.x;
	  mouse.y = e.y;
	  
	  if(!ball.classList.contains('actif')){
	  	ball.classList.add('actif');
	  	gsap.to(ball, {
	  		autoAlpha:1,
	  		duration:0.4,
	  		delay:0.2
	  	})
	  }
	});

	gsap.ticker.add(() => {
	  
	  // adjust speed for higher refresh monitors
	  const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
	  
	  pos.x += (mouse.x - pos.x) * dt;
	  pos.y += (mouse.y - pos.y) * dt;
	  xSet(pos.x);
	  ySet(pos.y);
	});



	//
	//
	//
	//
	document.querySelectorAll('#menu .droite button').forEach(btn => {

		if(window.innerWidth > 767){
			let tl = gsap.timeline({paused: true});

			tl.to(btn.querySelectorAll('.mot1 span'), {
				y:'-100%',
				autoAlpha:0,
				stagger:-0.03,
				ease:'power3.in',
				duration:0.25,
			}, "step0")
			tl.to(btn.querySelectorAll('.mot2 span'), {
				y:'0%',
				autoAlpha:1,
				stagger:-0.03,
				ease:'power3.out',
				duration:0.4
			}, "step1-=0.2")

			btn.addEventListener('mouseenter', () => {
				tl.play();
			})

			btn.addEventListener('mouseleave', () => {
				tl.reverse();
			})
		}
	})



	document.querySelectorAll('.invertMenu').forEach(el => {
		ScrollTrigger.create({
			trigger:el,
			start:'top top',
			end:'bottom top',
			onEnter:() => {
				document.querySelector('.toMenu').classList.add('invert')
				document.querySelector('header .logo svg').classList.add('invert')
			},
			onEnterBack:() => {
				document.querySelector('.toMenu').classList.add('invert')
				document.querySelector('header .logo svg').classList.add('invert')
			},
			onLeave:() => {
				document.querySelector('.toMenu').classList.remove('invert')
				document.querySelector('header .logo svg').classList.remove('invert')
			},
			onLeaveBack:() => {
				document.querySelector('.toMenu').classList.remove('invert')
				document.querySelector('header .logo svg').classList.remove('invert')
			},
		})
	})

	document.querySelectorAll('.invertMenu2').forEach(el => {
		ScrollTrigger.create({
			trigger:el,
			start:'top top',
			end:'bottom top',
			onEnter:() => {
				document.querySelector('.toMenu').classList.add('invert')
			},
			onEnterBack:() => {
				document.querySelector('.toMenu').classList.add('invert')
			},
			onLeave:() => {
				document.querySelector('.toMenu').classList.remove('invert')
			},
			onLeaveBack:() => {
				document.querySelector('.toMenu').classList.remove('invert')
			},
		})
	})

	gsap.fromTo('.brief1', {
		y: '-40%'
	}, {
		y:'0%',
		ease:'none',
		scrollTrigger:{
			trigger:'footer',
			start:'top 50%',
			end:'bottom bottom',
			scrub: 1
		}
	})
	gsap.fromTo('.brief2', {
		y: '-35%'
	}, {
		y:'0%',
		ease:'none',
		scrollTrigger:{
			trigger:'footer',
			start:'top 50%',
			end:'bottom bottom',
			scrub: 1
		}
	})
	gsap.fromTo('.brief3', {
		y: '-30%'
	}, {
		y:'0%',
		ease:'none',
		scrollTrigger:{
			trigger:'footer',
			start:'top 50%',
			end:'bottom bottom',
			scrub: 1
		}
	})
	
})

document.addEventListener('keydown', (e) => {

    e = e || window.event

    if(e.code=='Space') {
        e.preventDefault()
        if(document.querySelector('body').classList.contains('dev')) document.querySelector('body').classList.remove('dev')
        else document.querySelector('body').classList.add('dev')
    }
})

